import styled from 'styled-components';
import flags from '../../../images/translatorgo/flags.png'

export const HeaderWrapper = styled.div`
  height: 90px;
  @media (min-width: 520px) {
    background-image: url(${flags});
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: -18px;
    background-size: 310px;
  }
  @media (min-width: 769px) {
    height: 174px;
    background-size: auto;
  }
`;

