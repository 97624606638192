import styled from 'styled-components';
import circlesbg from '../../../images/translatorgo/circlesbg.png';
import mobile from '../../../images/translatorgo/carousel/iphone.png';

export const JumbotronWrapper = styled.div`
  grid-template-columns: [first] 45% [line2] 55%;
  @media (min-width: 769px) {
    grid-template-columns: [first] 63% [line2] 37%;
  }
  @media (min-width: 1051px) {
    grid-template-columns: [first] 55% [line2] 45%;
  }
  display: grid;
  &:before {
    content: '';
    background: #e9f2ff;
    background: linear-gradient(190deg, #b3d2ff 0%, #fff 55%);
    position: absolute;
    width: 100%;
    height: 1375px;
    left: 0;
    clip-path: ellipse(120% 484px at 50% 856px);
    z-index: -1;
    @media (min-width: 769px) {
      clip-path: ellipse(100% 484px at 50% 856px);
    }
    @media (min-width: 1050px) {
      clip-path: ellipse(55% 484px at 50% 856px);
    }
  }
`;

export const JumbotronImg = styled.div`
  position: relative;
  text-align: center;
  background-size: contain;
  grid-column-start: 2;
  grid-column-end: 3;
  width: 185px;
  @media (min-width: 370px) {
    width: auto;
  }
  @media (min-width: 769px) {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  @media (min-width: 1050px) {
    background: url(${circlesbg}) no-repeat;
  }
`;

export const JumbotronIphone = styled.div`
  background: url(${mobile});
  background-repeat: none;
  position: absolute;
  z-index: 99999;
  background-size: contain;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  pointer-events: none;
  height: 350px;
  width: 185px;
  @media (min-width: 769px) {
    height: 515px;
    width: 100%;
    margin-top: -30px;
  }
  @media (min-width: 1051px) {
    height: 639px;
    width: 337px;
  }
`;

export const JumbotronAddon = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  @media (min-width: 769px) {
    flex-direction: row;
    margin-bottom: 10px;
  }
  @media (min-width: 1051px) {
    margin-bottom: auto;
    flex-direction: row;
  }
`;

export const JumbotronCarousel = styled.div`
  overflow: hidden;
  border-radius: 33px;
  display: inline-block;
  height: 336px;
  width: 143px;
  padding-top: 20px;
  @media (min-width: 769px) {
    border-radius: 22px;
    height: 571px;
    width: 208px;
    padding: 0;
  }
  @media (min-width: 1051px) {
    height: 571px;
    width: 268px;
  }
`;

export const JumbotronHead = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  @media (min-width: 769px) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  h1 {
    @media (min-width: 769px) {
      margin-top: 0;
    }
  }
`;

export const JumbotronContent = styled.div`
  grid-column-start: 1;
  grid-column-end: 1;
  grid-area: 2;
  @media (min-width: 769px) {
  }
  @media (min-width: 769px) {
    margin-top: -25px;
  }
  @media (min-width: 1051px) {
    margin-top: -75px;
  }
`;

export const carouselWrapper = styled.h1``;

export const QrCodeContent = styled.div``;
export const ReviewGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
