import React from 'react';
import PropTypes from 'prop-types';
import { SubTitleMain } from './SubTitle.style';


const Subtitle = (content) => {
  return(
    <SubTitleMain>
      {content.content}
    </SubTitleMain>
  )
};

export default Subtitle;

Subtitle.propTypes = {
  content: PropTypes.string
};