import React from 'react';
import imgLogo from '../../../images/translatorgo/logo.png'
import { LogoWrapper, LogoImg } from './header.style';

const Logo = () => {
  return(
    <LogoWrapper>
      <LogoImg src={imgLogo} alt="Logo" />
    </LogoWrapper>
  )
};

export default Logo;
