import React from 'react';
import apple from '../../../images/translatorgo/logoEnApple.png';
import { LinkAppleWrapper, LinkAppleText } from './link.style';
import PropTypes from 'prop-types';

const LinkApp = (link) => {
  return (
    <LinkAppleWrapper>
      <a href={link.link} target="_blank" rel="noreferrer">
        <img width="160" src={apple} alt="Apple logo" />
        <LinkAppleText>Click and download the app</LinkAppleText>
      </a>
    </LinkAppleWrapper>
  );
};

export default LinkApp;

LinkApp.propTypes = {
  link: PropTypes.string,
};
