import React, { useEffect, useRef, useState } from 'react';
import { QrCodeWrapper } from './qr.style';

const QrCode = () => {
  const [result, setResult] = useState(null);
  const ref = useRef(null);
  let qrCode = null;

  if (typeof window !== 'undefined') {
    const QRCodeStyling = require('qr-code-styling');
    qrCode = new QRCodeStyling({
      width: 190,
      height: 190,
      dotsOptions: {
        color: '#000',
      },
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 0,
      },
      qrOptions: {
        errorCorrectionLevel: 'M',
      },
    });
  }

  useEffect(() => {
    const qrTimer = setTimeout(() => {
      const oneLinkURL = 'https://translatorgo.onelink.me/U5jM/7f18293a/';
      const gclid = 'af_sub4';
      const result =
        window.AF_SMART_SCRIPT &&
        window.AF_SMART_SCRIPT.generateOneLinkURL({
          oneLinkURL: oneLinkURL,
          afParameters: {
            googleClickIdKey: gclid,
          },
        });
      setResult(result ? result.clickURL : result);
    }, 500);
    return () => clearTimeout(qrTimer);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: result,
    });
  }, [result]);

  //translatorgo.onelink.me/U5jM/7f18293a/?af_js_web=true&af_ss_ver=2_1_0&[object Object]=234792837492

  https: useEffect(() => {
    qrCode.append(ref.current);
  });

  return (
    <QrCodeWrapper>
      <div ref={ref} />
    </QrCodeWrapper>
  );
};

export default QrCode;
