import styled from 'styled-components';

export const FooterWrapper = styled.div`
  font-size: 54px;
  margin-bottom: 110px;
  @media (min-width: 769px) {
    margin-bottom: 0;
  }
`;

export const Footerlinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  justify-content: center;
`;
export const Footerlink = styled.a`
  padding: 0 10px;
  color: #003166;
`;

export const FooterCopyright = styled.div`
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  margin-top: 30px;
`;
