import React from 'react';
import { FooterWrapper, FooterCopyright, Footerlinks, Footerlink } from './footer.style';


const Footer = () => {
  return(
    <FooterWrapper>
      <Footerlinks>
        <Footerlink href='/translations/privacy-policy'>
          Privacy policy
        </Footerlink>
        <Footerlink href='/translations/term-of-use'>
          Terms of Use
        </Footerlink>
      </Footerlinks>
      <FooterCopyright>
        © Copyright 2021 - Advanced Business Media SL - All rights reserved
      </FooterCopyright>
    </FooterWrapper>
  )
};

export default Footer;
