import React from 'react';
import PropTypes from 'prop-types';
import { IconsWrapper, IconWrapper, Icon, IconText } from './title.style';
import text from '../../../images/translatorgo/icons/text.png'
import voice from '../../../images/translatorgo/icons/voice.png'
import camera from '../../../images/translatorgo/icons/camera.png'
import save from '../../../images/translatorgo/icons/save.png'
import autocorrect from '../../../images/translatorgo/icons/autocorrect.png'
import offline from '../../../images/translatorgo/icons/offline.png'
import language from '../../../images/translatorgo/icons/language.png'
import share from '../../../images/translatorgo/icons/share.png'

const IconsImages = (icon) => {
  const images = {
    "text" : text,
    "voice" : voice,
    "camera" : camera,
    "save" : save,
    "autocorrect" : autocorrect,
    "offline" : offline,
    "language" : language,
    "share" : share
  }
  return images[icon]
}

const Icons = (icons) => {
  const AllIcons = icons.icons
  return(
    <IconsWrapper>
    {AllIcons.map((icon) => (
      <IconWrapper key={icon.iconName}>
        <Icon src={IconsImages(icon.iconName)} />
        <IconText>
          {icon.label}
        </IconText>
      </IconWrapper>
    ))}
      
    </IconsWrapper>
  )
};

export default Icons;

Icons.propTypes = {
  icons: PropTypes.array
};