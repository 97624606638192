import styled from 'styled-components';

const AppWrapper = styled.div`
  padding: 0 10px;
  @media (min-width: 320px) {
    width: 100%;
    margin: auto;
  }
  @media (min-width: 375px) {
    width: 768x;
    margin: auto;
  }
  @media (min-width: 769px) {
    width: 768px;
    margin: auto;
    padding: 0 20px;
  }
  @media (min-width: 1051px) {
    width: 1050px;
    margin: auto;
  }
  width: 100%
`;

export {
  AppWrapper
};
