import styled from 'styled-components';

export const IconsWrapper = styled.div`
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    width: 164px;
    @media (min-width: 769px) {
        flex-direction: row;
        width: 700px;
        margin-left: -10px;
        margin-top: 30px;
        align-items: center;
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    @media (min-width: 769px) {
        width: 300px;
    }
`;

export const Icon = styled.img`
  
`;

export const IconText = styled.div`
    font-size: 14px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    color: #003166;
    margin-left: 5px;
    @media (min-width: 769px) {
        font-size: 16px;
    }
`;
