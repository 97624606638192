import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css"
import img1 from "../../../images/translatorgo/carousel/01.png"
import img2 from "../../../images/translatorgo/carousel/02.png"
import img3 from "../../../images/translatorgo/carousel/03.png"
import img4 from "../../../images/translatorgo/carousel/04.png"

export default function MobileCarousel() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500
  };
  return (
    <Slider {...settings}>
        <div>
            <img src={img1} alt="img1" />
        </div>
        <div>
            <img src={img2} alt="img2"/>
        </div>
        <div>
            <img src={img3} alt="img3"/>
        </div>
        <div>
        <   img src={img4} alt="img4"/>
        </div>
    </Slider>
  );
}