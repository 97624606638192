import styled from 'styled-components';
// import flags from '../../../images/translatorgo/flags.png'

export const LogoWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const LogoImg = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
`;

