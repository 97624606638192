import React from 'react';
import PropTypes from 'prop-types';
import { TestimonialsWrapper, TestimonialsContent, TestimonialFooter, TestimonialsBox, TestimonialsHead } from './testimonials.style';
import startcomment from '../../../images/translatorgo/startcomment.png'

const Testimonials = (testimonials) => {
  const testimonialList = testimonials.testimonials
  return(
    <TestimonialsWrapper>
      {testimonialList.map((content) => (
        <TestimonialsBox key={content.userName}>
          <img src={startcomment} alt="stars" />
          <TestimonialsHead>
            {content.title}
          </TestimonialsHead>
          <TestimonialsContent>
            {content.testimonial.testimonial}
          </TestimonialsContent>
          <TestimonialFooter>
            {content.userName}
          </TestimonialFooter>
        </TestimonialsBox>
      ))}
    </TestimonialsWrapper>
  )
};

export default Testimonials;

Testimonials.propTypes = {
  content: PropTypes.string
};