import React, { Component, Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { appTheme } from 'common/src/theme/app';
import { ResetCSS } from 'common/src/assets/css/style';
import { AppWrapper } from '../../containers/Translator/app.style';
import Footer from '../../containers/Translator/Footer';
import Header from '../../containers/Translator/Header';
import '@redq/reuse-modal/es/index.css';
import SEO from '../../components/seo';
import Jumbotron from '../../containers/Translator/Jumbotron';
import Testimonials from '../../components/Translator/Testimonials';
import { Helmet } from 'react-helmet';

class Landing extends Component {
  render() {
    const context = this.props.pageContext;
    const landingData = this.props.data.contentfulLanding;

    return (
      <ThemeProvider theme={appTheme}>
        <Helmet>
          <script src="/onelink-smart-script-v2.1.0.js" />
          <meta
            name="facebook-domain-verification"
            content="k32sywe4t2a1dfjb3h2cr0go7x5emv"
          />
        </Helmet>
        <Fragment>
          <SEO title={context.slug} />
          <ResetCSS />
          <AppWrapper>
            <Header />
            <Jumbotron
              headerTitle={landingData.headerTitle.headerTitle}
              userRating={landingData.userRating}
              userReviews={landingData.userReviews}
              scanText={landingData.scanText}
              headerSubHeader={landingData.headerSubHeader.headerSubHeader}
              headerDescription={
                landingData.headerDescription.headerDescription
              }
              icons={landingData.icons}
            />
            <Testimonials testimonials={landingData.testimonials} />
            <Footer />
          </AppWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export const query = graphql`
  query landingQuery($id: String) {
    contentfulLanding(id: { eq: $id }) {
      userRating
      userReviews
      metaTitle
      slug
      project
      headerDescription {
        headerDescription
      }
      headerSubHeader {
        id
        headerSubHeader
      }
      headerTitle {
        id
        headerTitle
      }
      icons {
        iconName
        label
      }
      scanText
      testimonials {
        title
        userName
        starsN12345
        testimonial {
          testimonial
        }
      }
    }
  }
`;

export default Landing;
