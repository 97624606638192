import styled from 'styled-components';

export const TestimonialsWrapper = styled.div`
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
  color: #5f6368;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
  flex-direction: column;
  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

export const TestimonialsBox = styled.div`
  padding: 20px 40px;
  text-align: center;
  border: 1px solid #b1cef8;
  border-radius: 10px;
  box-shadow: 0 2px 5px #0031664a;
  width: 100%;
  margin-bottom: 20px;
  @media (min-width: 769px) {
    width: 30%;
    margin-bottom: 0;
  }

`;

export const TestimonialsContent = styled.p`
  font-size: 14px;
  color: #3a3d40;
  height: 40%;
  margin-bottom: 35px;
`;

export const TestimonialsHead = styled.p`
  margin: 10px 0;
  font-size: 18px;
  color: #0068ff;
  font-weight: 700;
`;

export const TestimonialFooter = styled.p`
  font-size: 12px;
  color: #5f6368;
  margin: 0;
  font-weight: 700;
`;
