import styled from 'styled-components';

export const RatingWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  @media (min-width: 769px) {
    display: flex;
    flex-flow: column wrap;
    align-content: flex-start;
    padding: 0 0 20px 20px;
    align-items: flex-start;
  }
`;

export const RatingStars = styled.img`
  margin: 0 10px 0 0;
  @media (min-width: 769px) {
    margin-left: -5px;
    margin-bottom: 10px;
  }
`;

export const RatingText = styled.span`
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
`;
