import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
  JumbotronWrapper,
  JumbotronImg,
  JumbotronHead,
  JumbotronContent,
  JumbotronAddon,
  JumbotronIphone,
  JumbotronCarousel,
  QrCodeContent,
  ReviewGroup,
} from './jumbotron.style';
import Title from '../../../components/Translator/Title';
import Rating from '../../../components/Translator/Rating';
import Reviews from '../../../components/Translator/Reviews';
import QrCode from '../../../components/Translator/QrCode';
import SubTitle from '../../../components/Translator/SubTitle';
import Description from '../../../components/Translator/Description';
import Icons from '../../../components/Translator/Icons';
import MobileCarousel from '../../../components/Translator/MobileCarousel';
import LinkApp from '../../../components/Translator/LinkApp';

const Jumbotron = ({
  headerTitle,
  headerSubHeader,
  headerDescription,
  icons,
}) => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const linkTimer = setTimeout(() => {
      const oneLinkURL = 'https://translatorgo.onelink.me/U5jM/o7b05z4v/';
      const result =
        window.AF_SMART_SCRIPT &&
        window.AF_SMART_SCRIPT.generateOneLinkURL({
          oneLinkURL: oneLinkURL,
          afParameters: {
            afSub1: {
              keys: ['fbclid'],
            },
          },
        });
      setResult(result ? result.clickURL : result);
    }, 500);
    return () => clearTimeout(linkTimer);
  }, []);

  return (
    <JumbotronWrapper>
      <JumbotronHead>
        <Title content={headerTitle} />
        <JumbotronAddon>
          <QrCodeContent>
            <QrCode />
            <LinkApp link={result} />
          </QrCodeContent>
          <ReviewGroup>
            <Reviews />
            <Rating />
          </ReviewGroup>
        </JumbotronAddon>
      </JumbotronHead>
      <JumbotronImg>
        <JumbotronCarousel>
          <JumbotronIphone />
          <MobileCarousel />
        </JumbotronCarousel>
      </JumbotronImg>
      <JumbotronContent>
        <SubTitle content={headerSubHeader} />
        <Description content={headerDescription} />
        <Icons icons={icons} />
      </JumbotronContent>
    </JumbotronWrapper>
  );
};

export default Jumbotron;
