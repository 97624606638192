import React from 'react';
import PropTypes from 'prop-types';
import { TitleMain } from './title.style';


const Title = (content) => {
  return(
    <TitleMain>
      {content.content}
    </TitleMain>
  )
};

export default Title;

Title.propTypes = {
  content: PropTypes.string
};