import styled from 'styled-components';

export const SubTitleMain = styled.h2`
  font-size: 25px;
  font-family: 'Roboto', sans-serif;
  color: #131415;
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
`;
