import React from 'react';
import { RatingWrapper, RatingText, RatingStars } from './rating.style';
import stars from '../../../images/translatorgo/starsrating.svg'


const Rating = () => {
  return(
    <RatingWrapper>
      <RatingStars src={stars} alt="Stars"  />
      <RatingText>
        Users Rating 4,7
      </RatingText>
    </RatingWrapper>
  )
};

export default Rating;
