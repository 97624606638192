import styled from 'styled-components';

export const LinkAppleText = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-family: 'Roboto', sans-serif;
  color: #0049b8;
  font-weight: 700;
  color: #fff;
  margin-left: 10px;
  max-width: 195px;
`;

export const LinkAppleWrapper = styled.div`
  align-items: center;
  border: 1px solid #b1cef8;
  padding: 12px;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  margin: 0;
  left: 0;
  bottom: 0;
  background: #009df8;
  z-index: 100000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  @media (min-width: 769px) {
    display: none;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
