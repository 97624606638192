import React from 'react';
import Logo from '../../../components/Translator/Logo'
import {HeaderWrapper} from './header.style'



const Header = () => {
  return(
    <HeaderWrapper>
      <Logo />
    </HeaderWrapper>
  )
};

export default Header;
