import styled from 'styled-components';

export const TitleMain = styled.h1`
  font-family: 'Roboto', sans-serif;
  background: #fff;
  color: #3a3d40;
  text-align: center;
  font-size: 28px;
  @media (min-width: 769px) {
    text-align: left;
    font-size: 48px;
  }
  @media (min-width: 1051px) {
    font-size: 54px;
  }
`;
