import styled from 'styled-components';

export const DescriptionBig = styled.p`
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  color: #5f6368;
  display: none;
  @media (min-width: 769px) {
    display: block;
  }
`;
