import styled from 'styled-components';

export const ReviewsWrapper = styled.div`
  display: none;
  align-items: left;
  @media (min-width: 769px) {
    display: flex;
    flex-flow: column wrap;
    align-content: flex-start;
    padding: 20px 0 0 20px;
  }
`;

export const ReviewsText = styled.span`
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
  margin-top: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eaeaec;
  margin-bottom: 16px;
`;
