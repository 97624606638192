import React from 'react';
import PropTypes from 'prop-types';
import { DescriptionBig } from './Description.style';


const Description = (content) => {
  return(
    <DescriptionBig>
      {content.content}
    </DescriptionBig>
  )
};

export default Description;

Description.propTypes = {
  content: PropTypes.string
};