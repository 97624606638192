import styled from 'styled-components';

export const QrCodeWrapper = styled.div`
  display: flex;

  align-items: center;
  border: 1px solid #b1cef8;
  padding: 10px 12px 8px 12px;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  margin: 0;
  left: 0;
  bottom: 0;
  background: #009df8;
  z-index: 100000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  @media (min-width: 769px) {
    flex-direction: column;
    border-radius: 10px;
    max-width: 210px;
    position: relative;
  }
  img {
    width: 72px;
    height: 72px;
    @media (min-width: 769px) {
      width: auto;
      height: 184px;
      margin-bottom: 15px;
    }
  }
  canvas {
    display: none;
    @media (min-width: 769px) {
      display: block;
      // outline: 8px solid #fff;
      // margin-top: 10px;
      // margin-bottom: 16px;
    }
  }
`;

export const QrCodeText = styled.span`
  font-size: 14px;
  line-height: 18px;
  font-family: 'Roboto', sans-serif;
  color: #0049b8;
  font-weight: 700;
  color: #fff;
  margin-left: 10px;
  max-width: 195px;
  @media (min-width: 769px) {
    margin-left: 0px;
    text-align: center;
  }
`;
