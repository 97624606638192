import React from 'react';
import { ReviewsWrapper, ReviewsText } from './reviews.style';
import apple from '../../../images/translatorgo/logoEnApple.png';

const Reviews = () => {
  return (
    <ReviewsWrapper>
      <img width="160" src={apple} alt="Apple logo" />
      <ReviewsText>300+ Reviews</ReviewsText>
    </ReviewsWrapper>
  );
};

export default Reviews;
